import { MenuItem } from '../models/menu-item.model';

export const MENU_ITEMS: Array<MenuItem> = [
  {
    label: 'l-support',
    subItems: [
      {
        label: 'l-support',
        link: 'support',
      },
      {
        label: 'l-control-panel',
        link: 'control-panel',
      },
    ],
  },
  {
    label: 'l-management',
    subItems: [
      {
        label: 'l-contract-update',
        link: 'contract-update',
      },
      {
        label: 'l-installment-update',
        link: 'installment-update',
      },
      {
        label: 'l-installment-audit',
        link: 'installment-audit',
      },
      {
        label: 'l-future-installments',
        link: 'future-installments',
      },
      {
        label: 'l-environment-maitenance',
        link: 'environment-maitenance',
      },
       {
        label: 'l-event-reprocessing',
        link: 'event-reprocessing',
      },
    ],
  },
  {
    label: 'l-users',
    link: 'user-access',
  },
];
