import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../../shared/models/menu-item.model';
import { MENU_ITEMS } from '../../shared/settings/menu.settings';
import { State } from '../../shared/state/state';

@Injectable({ providedIn: 'root' })
export class MenuService extends State<MenuItem[]> {
  private menuItems = MENU_ITEMS;
  private authorizedMenus = [];
  constructor(private translate: TranslateService) {
    super();
  }

  async initAuthorizedMenus(): Promise<void> {
    try {
      this.authorizedMenus = await this.checkAuthorizedItems();
      await this.setCurrentState(this.authorizedMenus);
    } catch (error) {
      console.log('Erro na inicialização de Menus. Erro: ' + error);
    }
  }

  private async checkAuthorizedItems(): Promise<MenuItem[]> {
    this.authorizedMenus = [];
    this.menuItems.forEach((menu) => {
      this.authorizedMenus.push(menu);
      if (menu.subItems) {
        this.translateLabels(menu.subItems);
      }
    });
    return this.translateLabels(this.authorizedMenus);
  }

  private translateLabels(items: MenuItem[]): MenuItem[] {
    items = items.map((item) => {
      this.translate.get([item.label]).subscribe((translate) => {
        item.label = translate[item.label];
      });
      return item;
    });
    return items;
  }
}
